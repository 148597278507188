/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';

export function onClientEntry () {
    window.addEventListener('load', () => {
        document.body.className = document.body.className.replace(/\bno-js\b/, '');
    });
}